/** @define select-multi; weak */
.select-multi {
  position: relative;
  user-select: none;
}
.select-multi select {
  display: none;
}
.select-multi__screen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: none;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.select-multi__display {
  position: relative;
  z-index: 9;
  width: 100%;
  min-height: 43px;
  padding: 3px 40px 3px 10px;
}
.select-multi__display .item {
  position: relative;
  display: flex;
  align-items: center;
  width: inherit;
  max-width: max-content;
  margin: 1px 5px 1px 0;
  padding: 2px 5px 2px 10px;
  overflow: hidden;
  color: #000000;
  border: 1px solid #bebebe;
}
.select-multi__display .btnclose {
  position: relative;
  display: flex;
  height: 20px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.select-multi__display .btnclose:hover {
  opacity: 0.75;
}
.select-multi__display .icon_svg {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  color: red;
}
.select-multi__display .item.remove {
  -webkit-animation: removeSelected 0.2s, hide 1s infinite;
  animation: removeSelected 0.2s, hide 1s infinite;
  -webkit-animation-delay: 0s, 0.2s;
  animation-delay: 0s, 0.2s;
}
.select-multi__display .item.add {
  -webkit-animation: addSelected 0.2s;
  animation: addSelected 0.2s;
}
.select-multi__display .item.hide {
  display: none;
}
.select-multi__options {
  position: absolute;
  z-index: 1;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  background: #bebebe;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.25s linear;
}
.select-multi__options a {
  position: relative;
  display: block;
  height: 40px;
  max-height: 40px;
  padding: 0 20px;
  overflow: hidden;
  color: #000000;
  line-height: 40px;
  transition: all 1s;
}
.select-multi__options a:hover {
  color: #be2929;
  text-decoration: none;
  background: #ffffff;
  cursor: pointer;
}
.select-multi__options a.remove {
  max-height: 0;
  animation: removeOption 0.2s;
}
.select-multi__options a.add {
  -webkit-animation: addOption 0.2s;
  animation: addOption 0.2s;
}
.select-multi__options a.hide {
  display: none;
}
.select-multi--open {
  z-index: 100;
}
.select-multi--open .select-multi__screen {
  z-index: 100;
  display: block;
}
.select-multi--open .select-multi__options {
  z-index: 999;
  max-height: 2000px;
}
.select-multi--open .select-multi__display {
  z-index: 200;
}
@keyframes pop {
  from {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes removeOption {
  from {
    max-height: 40px;
  }
  to {
    max-height: 0;
  }
}
@keyframes addOption {
  from {
    max-height: 0;
  }
  to {
    max-height: 40px;
  }
}
@keyframes removeSelected {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@keyframes addSelected {
  from {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes hide {
  from,
  to {
    max-width: 0;
    max-height: 0;
    margin: 0;
    padding: 0;
    border-width: 0;
  }
}
/*# sourceMappingURL=css/select-multi.css.map */